import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import StorygramGUI from './components/StorygramWrapper';
import { MetasonData } from '../previewData.ts';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <p>{`The Storygram allows you to explore and visualize sequential groupings in your data.`}</p>
    <p>{`Make shure to give a look to our `}<a parentName="p" {...{
        "href": "https://storygram-demo.netlify.app/"
      }}>{`examples`}</a>{` and the beta version of our `}<a parentName="p" {...{
        "href": "https://storygram-explorer.netlify.app/"
      }}>{`interactive storygram explorer`}</a>{`.`}</p>
    <p>{`In the example below an excerpt of the career of Bass Player Marcus Miller is shown. Data courtesy from `}<a parentName="p" {...{
        "href": "https://metason.net/"
      }}>{`metason.net`}</a>{`. Feel free to change the code below to familiarize with the Storygram's functionality. You'll be able to do so through the whole documentation.`}</p>
    <Playground __position={0} __code={'<StorygramGUI\n  data={MetasonData()}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'release_year\',\n    actorArrayField: \'participants\',\n    filterGroupAmt: [3, undefined],\n    filterGroupSize: [5, 15],\n    filterEventValue: [1982, 1989],\n    eventDescription: event =>\n      event.data.release_title + \', \' + event.data.release_year,\n    url: (event, actor) =>\n      \'https://music.metason.net/artistinfo?name=\' + String(actor.actorID),\n    eventUrl: event =>\n      \'https://www.google.ch/search?q=\' +\n      event.data.release_title +\n      \' \' +\n      event.eventValue,\n    filterActorCustom: actor => actor.actorID !== \'Marcus Miller\',\n    marginRight: 300,\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      MetasonData,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      <StorygramGUI data={MetasonData()} config={{
        dataFormat: 'array',
        eventField: 'release_year',
        actorArrayField: 'participants',
        filterGroupAmt: [3, undefined],
        filterGroupSize: [5, 15],
        filterEventValue: [1982, 1989],
        eventDescription: event => event.data.release_title + ', ' + event.data.release_year,
        url: (event, actor) => 'https://music.metason.net/artistinfo?name=' + String(actor.actorID),
        eventUrl: event => 'https://www.google.ch/search?q=' + event.data.release_title + " " + event.eventValue,
        filterActorCustom: actor => actor.actorID !== 'Marcus Miller',
        marginRight: 300
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <p>{`After playing around with the code you might have noticed two things:`}</p>
    <p>{`The Storygram doesn't look always the same. This is due to the fact that it tries to optimize the clarity of the visualization by reducing edge crossings.`}</p>
    <p>{`The size of the Storygram is set automatically based on the amount of actors and events to be visualized.`}</p>
    <h2 {...{
      "id": "bug-reports--questions"
    }}>{`Bug Reports & Questions`}</h2>
    <p>{`The StoryGram is MIT-licensed and the source is available on `}<a parentName="p" {...{
        "href": "https://github.com/FranzFlueckiger/Storygram"
      }}>{`github`}</a>{`. If any questions or issues come up as you use the StoryGram, please get in touch via Git Issues.`}</p>
    <h2 {...{
      "id": "contributors"
    }}>{`Contributors`}</h2>
    <p>{`The StoryGram was invented and developed by Franz Flückiger and then integrated into the Bachelor Thesis in the ZHaW.
Many thanks to the contributions of Stefan Guggisberg and Philipp Ackermann. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      